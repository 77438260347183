import React, { ReactNode } from 'react';

import styles from './CallToAction.module.scss';

interface IProps {
  children: ReactNode;
  target?: string;
  subline?: string;
}

const CallToAction: React.FC<IProps> = ({ children, target, subline }) => {
  return (
    <section className={styles.signup}>
      <div>
        <a
          href={target}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.signup__button}
        >
          {children}
          {subline && <small className={styles.signup__subline}>{subline}</small>}
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
