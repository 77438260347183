import React from 'react';
// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';
import CallToAction from 'gatsby-theme-newsletter/src/components/CallToAction';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

import styles from './index.module.scss';

const SignUpCTA = () => (
  <CallToAction target="https://app.qrpid.io/sign-up" subline={'3 QR-Codes for free. Forever!'}>
    Start Managing your QR-Codes
  </CallToAction>
);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="Manage anything with our next level QR-Codes."
      description="Web based software to manage anything with our next level QR-Codes for service and support, distribution, tracking and feedback solutions."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <div className={styles.index__hero}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6 large-6">
            <h1>Manage anything with our next level QR-Codes.</h1>
            <p>
              We use QR-Codes to engage people in digital workflows. From retrieving lost luggage to
              letting customers register their products for service and support.
            </p>
            <SignUpCTA />
          </div>
          <div className="cell small-12 medium-6 large-6">
            {/* <img className={styles.index__hero_device} src="qrpid_device_01.png" /> */}
            <div className={styles.index__phone}>
              <div className={styles.index__phone_container}>
                <div className={styles.index__phone_content}>
                  <video className={styles.index__phone_screen} autoPlay loop>
                    <source src="QRpid_Lost-And-Found_Prototype.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className={styles.steps}>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <h2 className="text-center">How it works.</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.steps__step}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6 text-center">
              <div className={styles.steps__image}>
                <img className="myimg" src="qrpid_steps_01-tag.png" />
              </div>
            </div>
            <div className="cell small-12 medium-6">
              <h3>1. Tag your gear.</h3>
              <p>Put one of our unique QR-Codes on a piece of equipment of your choice.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.steps__step}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6 text-center">
              <div className={styles.steps__image}>
                <img className="myimg" src="qrpid_steps_02-scan.png" />
              </div>
            </div>
            <div className="cell small-12 medium-6">
              <h3>2. Scan the QR-code.</h3>
              <p>Use your Smartphone to scan the QR-code.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.steps__step}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6 text-center">
              <div className={styles.steps__image}>
                <img className="myimg" src="qrpid_steps_03-manage.png" />
              </div>
            </div>
            <div className="cell small-12 medium-6">
              <h3>3. Manage via Web App.</h3>
              <p>
                Choose from actions like redirecting to a website, showing related information,
                contacting the QR-code owner, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.steps__cta}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 text-center">
              <h3>Do you want to manage your assets with QRpid-codes, too?</h3>
              <SignUpCTA />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.benefits}>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <h2 className="text-center">Your benefits.</h2>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <h3>Change linked action - at any time.</h3>
              <p>
                While your specific device changes through its lifecycle, you can change the
                displayed information and actions dynamically. From production to sales to
                distribution and second-hand sales. No more one-way QR-codes that can’t change after
                being printed once.
              </p>
            </div>
            <div className="cell small-12 medium-6">
              <h3>Track via Dashboard.</h3>
              <p>Put one of your unique QRpid stickers on a piece of equipment of your choice.</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <h3>Access with any Smartphone.</h3>
              <p>
                Thanks to our mobile friendly Web App, no prior installation is necessary, you just
                need an internet connection! Current models have a QR-code scanner already
                integrated. If not, there are plenty of QR-code-readers available in all App Stores.
              </p>
            </div>
            <div className="cell small-12 medium-6">
              <h3>Order official labels or print your own.</h3>
              <p>Put one of your unique QRpid stickers on a piece of equipment of your choice.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.benefits__cta}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 text-center">
              <h3>Do you want to benefit from QRpid-codes, too?</h3>
              <SignUpCTA />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.cases}>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <h2 className="text-center">Use cases.</h2>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <h3>Service & Support.</h3>
              <p>
                Help your customers by providing all manuals, frequently asked questions, contact
                persons and a support form on one mobile-friendly webpage, that’s just one scan of a
                QR-Code away. e.g., printers, conference room setup, tenant information, hotel
                rooms.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <h3>Lost & Found.</h3>
              <p>
                This module is our solution to tag mobile belongings like bags, suitcases,
                backpacks, or briefcases. This module connects the QR-Code with all information of
                the specific asset. Image, description, reward, address, etc. We also offer an
                anonymous contact form, that the finder can use, without having to expose your (or
                his/hers) personal contact information.
              </p>
            </div>
            <div className="cell small-12 medium-6">
              <h3>Distribution.</h3>
              <p>
                Extend your products context by providing a QR-Code with additional information and
                mobile-optimized tools for lead generation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <h3>Tracking.</h3>
              <p>
                You are repairing, inspecting or storing things for your customers? Excellent. Put a
                QR-Code on it and let your customer subscribe to any news regarding this. You get an
                overview of the current location and your customers stay in the loop without
                contacting you all the time.
              </p>
            </div>
            <div className="cell small-12 medium-6">
              <h3>Feedback.</h3>
              <p>
                By putting a QR-Code that’s linked to our feedback module on your invoice, waiting
                room or counter you can collect information from those, who are most valuable for
                your business: your customers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cases__cta}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 text-center">
              <h3>Interested in other scenarios to use QRpid-codes, too?</h3>
              <SignUpCTA />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={styles.faq}>
      <div>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <h3 className="text-center">Frequently asked questions.</h3>
            </div>
          </div>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-offset-1 medium-10 large-offset-2 large-8">
              Are you currently looking for beta users?
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faq__cta}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 text-center">
              <h3>Still questions about QRpid-codes?</h3>
              <SignUpCTA />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
